import moment from 'moment'

export const setTimeMixin = {
    methods: {

        setTimeToEvent() {
            let interval = 1000;
            if (this.BOOKING_DETAILS.timeslots.length > 0) {
                let slot = this.BOOKING_DETAILS.timeslots[0]
                let eventTime = moment(slot.start_date)
                let currentTime = moment();
                let duration = moment.duration(eventTime.diff(currentTime))
                duration = moment.duration(duration - interval, 'milliseconds')
                this.eventStart.day = duration.days()
                this.eventStart.hours = duration.hours()
                this.eventStart.minutes = duration.minutes()
            }
        }
    }

}
export const setStartDate = {
    methods: {
        setStartDate() {
            let interval = 10;
            if (this.booking.event.timeslots.length > 0) {
                let slot = this.booking.event.timeslots[0]
                let eventTime = moment(slot.start_date)
                let currentTime = moment();
                let duration = moment.duration(eventTime.diff(currentTime))
                duration = moment.duration(duration - interval, 'milliseconds')
                this.eventStart.day = duration.days()
                this.eventStart.hours = duration.hours()
                this.eventStart.minutes = duration.minutes()
            }
        }
    }
}