<template>
  <b-card class="event-infos-card px-lg-4 pb-4" v-if="BOOKING_DETAILS">
    <b-row class="pl-0">
      <b-col  sm="12" md="12" lg="4" xl="4" class="py-2">
        <b-alert
            variant="warning"
            show>
          <div class="alert-body">
            <feather-icon
                icon="ClockIcon"
                class="mr-50"/>
            {{ $t('Event starts in') }} : {{ eventStart.day }} {{ $t('Days') }} {{ eventStart.hours }} {{ $t('Hours') }} {{ eventStart.minutes }} {{ $t('Minutes') }}
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="mt-3 justify-content-between">
      <b-col  sm="12" md="12" lg="5" xl="5" class="shadow">
        <h3 class="h3 py-3 text-center">{{ $t('Cancellation Condition') }} </h3>
        <b-alert
            variant="danger"
            show>
          <h4 class="alert-heading">
            {{ $t('Event starts in') }}  : {{ eventStart.day }}  {{ $t('Days') }} {{ eventStart.hours }} {{ $t('Hours') }} {{ eventStart.minutes }} {{ $t('Minutes') }}
          </h4>
          <div class="alert-body">
            {{ $t('Your request falls under Cancellation Condition') }}  {{ cancellationIndex }}
          </div>
        </b-alert>
        <div class="cansel_condition_wrp shadow w-100 py-2 px-1 mb-2">
          <p class="text_top text-danger">
            <feather-icon class="cursor-pointer" style="margin-right: 5px" icon="XCircleIcon" size="32"/>
              {{ $t('Cancellation Condition') }}
          </p>
          <p class="text_bottom">  {{ $t('The cancellation fee is') }} {{ creditsCancellation }}  {{ $t('Credits') }}. {{ $t('We will therefore refund') }}
            {{ creditsRefund }}  {{ $t('Credits to your account') }}</p>
        </div>

      </b-col>
      <b-col sm="12" md="12" lg="5" xl="5" class="shadow px-4">
        <h3 class="h3 py-3 text-center">{{ $t('Cancellation Condition') }} </h3>
        <div class="info_row d-flex justify-content-between"><p class="info_txt"> {{ $t('Credits paid for the Event') }}</p>
          <p class="info_credits font-weight-bold">{{ creditsEvent }}</p></div>
        <div class="info_row d-flex justify-content-between"><p class="info_txt"> {{ $t('Cancellation Costs in Credits') }}</p>
          <p class="info_credits text-danger font-weight-bold">{{ creditsCancellation }}</p></div>
        <hr class="mt-3">
        <div class="info_row d-flex justify-content-between"><p class="info_txt"> {{ $t('Credits refund') }}</p>
          <p class="info_credits text-danger font-weight-bold">{{ creditsRefund }}</p></div>
        <b-button
            @click="cancellationBooking"
            variant="danger"
            class="mb-2"
            type="submit"
            size="sm"
        > {{ $t('Cancel the Event now') }}
        </b-button>
      </b-col>
    </b-row>
    <b-modal
        v-if="inProgress"
        id="modal-success"
        centered
        hide-footer
        hide-header
        :no-close-on-backdrop="true"
        :cancel-disabled="true"
    >

      <div class="d-flex align-items-center flex-column justify-content-center py-3">
        <feather-icon class="text-success"   icon="CheckCircleIcon" size="64"/>
        <h3 class="h3 mt-1"> Your booking got canceled!</h3>
        <p>We will refund the amount to your wallet</p>
        <b-button

            variant="success"
            class="mr-2"
            type="submit"
            size="sm"
            :to="{ name: 'events-list'}"
        > {{ $t('Book new Event') }}
        </b-button>
      </div>
    </b-modal>
  </b-card>


</template>

<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  BButton,
  BModal,
  VBModal

} from "bootstrap-vue";
import {mapActions, mapGetters} from "vuex";
import axios from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {setTimeMixin} from "@/mixins/setTimeMixin";

export default {
  name: "BookingCancellation",
  mixins:[setTimeMixin],
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    VBModal

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      creditsEvent: null,
      creditsRefund: null,
      creditsCancellation: null,
      cancellationIndex: null,
      percentFall: null,
      inProgress:null,
      eventStart: {
        day: 0,
        hours: 0,
        minutes: 0
      }
    }
  },
  computed: {
    ...mapGetters(['BOOKING_DETAILS']),
  },
  methods: {
    ...mapActions(['GET_BOOKING_DETAILS']),
    cancellationBooking() {
      axios.get(`/client/booking/cancel/${this.BOOKING_DETAILS.id}`).then(() => {

        this.$root.$emit('bv::show::modal', 'modal-success')
      })
    },

  },
  mounted() {
    this.GET_BOOKING_DETAILS(this.$route.params.bookingid).then(() => {
      this.creditsEvent = this.BOOKING_DETAILS.event.credits
      this.creditsCancellation = this.BOOKING_DETAILS.CancellationСonditions.activeCondition.credits
      this.creditsRefund = this.creditsEvent - this.creditsCancellation
      this.percentFall = this.BOOKING_DETAILS.CancellationСonditions.percentFall
      this.cancellationIndex = this.percentFall = this.BOOKING_DETAILS.CancellationСonditions.activeCondition.index
      this.inProgress = this.BOOKING_DETAILS.CancellationСonditions.inProgress
      setTimeout(() => {
        this.setTimeToEvent()
      }, 1000)
    })
  }
}
</script>

<style scoped>
.text_bottom {
  padding-left: 41px;
  margin-top: -13px;
}
</style>
